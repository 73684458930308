// Main Style
@charset "UTF-8";
@import 'foundation/foundation';
@import 'vendor/font/icons';

// 定義
@import 'layout/variable_mixin_function';

// Swiper
@import 'vendor/swiper/_swiper';
@import 'vendor/swiper/_custom';

// --------------------------------------------------------
.headline-box {
	margin-bottom: rem-calc(20);

	@include breakpoint(xlarge) {
		margin-bottom: rem-calc(40);
	}
}

.sec-entitle {
	margin-bottom: rem-calc(16);
	color: $gray-300;
	line-height: 1;

	@include breakpoint(xxlarge) {
		margin-bottom: rem-calc(20);
	}
}

.sec-title {
	color: $black;
}

// --------------------------------------------------------
// Sec: index
.sec-kv {
	@include fc(center, stretch);

	position: relative;
	flex-wrap: wrap;

	@media screen and (min-width: 1024px) and (max-height: 900px) {
		min-height: rem-calc(768);
		// max-height: 100vh;
	}

	@include breakpoint(large) {
		height: 100vh;
	}

	// Left
	.kv_nav {
		@include fc(flex-start, flex-start);
		flex-direction: column;
		box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.25);

		@include breakpoint(large) {
			max-height: 100vh;
			width: rem-calc(250);
		}

		@include breakpoint(xxlarge) {
			width: rem-calc(400);
		}

		@include breakpoint(big) {
			width: rem-calc(485);
		}
	}

	.kv_logo {
		@include fc(center, center);

		position: relative;
		z-index: 2;
		height: rem-calc(90);
		width: 100%;
		background-color: $white;

		@include breakpoint(big) {
			height: rem-calc(118);
		}

		&::before,
		&::after {
			pointer-events: none;
			content: '';
			display: block;
			position: absolute;
			z-index: 5;
			top: 0;
			height: 100%;
		}

		&::before {
			left: 0;
			width: rem-calc(26);
			background: linear-gradient(168.81deg, #dabcbb -23.11%, #f8e2e1 35.61%, #edc7c5 72.16%, #db7c78 124.06%);
		}

		&::after {
			left: rem-calc(26);
			width: rem-calc(12);
			background: linear-gradient(168.81deg,
					#bd3b36 -23.11%,
					#dabcbb 12.54%,
					#f8e2e1 35.61%,
					#edc7c5 57.64%,
					#bd3b36 108.73%);
		}

		.img {
			position: relative;
			z-index: 5;
			width: rem-calc(150);

			@include breakpoint(big) {
				width: rem-calc(220);
			}
		}

		&_deco {
			pointer-events: none;
			position: absolute;
			z-index: 1;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;

			&::before,
			&::after {
				content: '';
				display: block;
				height: 100%;
				width: 100%;
			}

			&::before {
				position: relative;
				z-index: 2;
				background-color: $white;
			}

			&::after {
				position: absolute;
				z-index: -1;
				top: calc(100% - 54px);
				height: rem-calc(108);
				background: radial-gradient(75.98% 50% at 50% 50%,
						rgba(143, 143, 143, 0.2) 0%,
						rgba(217, 217, 217, 0) 100%);
			}
		}
	}

	.kv_menu {
		position: relative;
		z-index: 1;
		padding: rem-calc(30 0);
		width: 100%;
		flex: 1;
		overflow-y: auto;

		@media screen and (min-width: 1024px) and (max-height: 900px) {
			padding: rem-calc(30 0);
		}

		@include breakpoint(xxlarge) {
			padding: rem-calc(126 0);
		}

		&_link {
			display: block;
			padding: rem-calc(17 0);
			font-weight: 500;
			color: $black;
			text-align: center;

			@include breakpoint-hover {
				&:hover {
					font-weight: 700;
					color: $primary-500;
				}
			}
		}
	}

	.kv_ctrl {
		width: 100%;

		&_link {
			display: block;
			padding: rem-calc(25 0);
			font-weight: 500;
			text-align: center;

			&:not(:hover) {
				color: $black;
			}

			>.be-icon {
				margin-right: rem-calc(12);
				font-size: rem-calc(24);
			}
		}
	}

	// Right
	.kv_cont {
		width: 100%;

		@include breakpoint(large) {
			@include fc(flex-start, inherit);

			flex-direction: column;
			flex: 1;
			width: calc(100% - 485px);
		}
	}

	// Swiper
	.headline-box {
		position: absolute;
		z-index: 5;
		top: percentage(45/425);
		left: percentage(25/375);
		opacity: 0;
		transition: transform 0.8s, opacity 0.8s;
		transform: translateX(30px);

		@include breakpoint(medium) {
			top: 50%;
			left: percentage(90/1435);
			transform: translate(50px, -50%);
		}

		.btn {
			box-sizing: border-box;
			margin-top: rem-calc(22);

			@include breakpoint(medium) {
				margin-top: rem-calc(30);
			}
		}
	}

	.sec-title {
		color: $white;
		font-weight: 700;
	}

	.kv_swiper {
		position: relative;

		@include breakpoint(large) {
			height: calc(100vh - 140px);

			&::after {
				content: '';
				display: block;
				z-index: 1;
				width: 100%;
				height: rem-calc(10);
				background: linear-gradient(90deg, #4d4d4d 2%, #f6f6f6 48.96%, #9c9c9c 96%);
			}
		}

		&_ctrl {
			position: absolute;
			z-index: 1;

			@include breakpoint(medium down) {
				left: 50%;
				bottom: rem-calc(20);
				transform: translateX(-50%);
			}

			@include breakpoint(large) {
				right: rem-calc(60);
				bottom: rem-calc(30);
			}
		}

		.rwd-bg {
			@include breakpoint(medium only) {
				height: 100%;
			}

			&::before {
				padding-top: percentage(425/375);

				@include breakpoint(medium) {
					padding-top: 0;
				}
			}

			@include breakpoint(large) {
				height: 100%;
			}
		}

		.swiper-button-wrap {
			margin-left: auto;

			@include breakpoint(medium down) {
				margin-right: auto;
				margin-bottom: rem-calc(20);
			}

			@include breakpoint(large) {
				margin-bottom: rem-calc(34);
			}
		}
	}

	.swiper-container {
		@include breakpoint(large) {
			height: calc(100% - 10px);
		}
	}

	.swiper-slide {
		&-active {
			.headline-box {
				opacity: 1;
				transform: translateX(0);

				@include breakpoint(medium) {
					transform: translate(0px, -50%);
				}
			}
		}
	}

	// 快速連結
	.kv_fastlink {
		position: relative;

		@include breakpoint(large) {
			@include fc(center, stretch);
		}

		&_list {
			@include fc(center, center);

			background-color: $gray-100;

			@include breakpoint(medium down) {
				flex-wrap: wrap;
			}

			@include breakpoint(large) {
				flex: 5;
			}

			.kv_fastlink_link {
				position: relative;
				padding: rem-calc(15 0);
				height: 100%;
				overflow: hidden;

				@include breakpoint(medium) {
					padding: rem-calc(15 0);
				}
			}
		}

		&_item {
			@include breakpoint(medium down) {
				width: 20%;
			}

			@include breakpoint(small down) {
				width: 33%;
			}

			@include breakpoint(large) {
				height: 100%;
				flex: 1;
			}

			&.w-sm-50 {
				@include breakpoint(small down) {
					width: 50%;
				}
			}
		}

		&_other {
			@include breakpoint(medium down) {
				@include fc(center, center);
			}

			@include breakpoint(large) {
				flex: 1;
			}

			.kv_fastlink_link {
				flex-direction: inherit;
				padding: rem-calc(20 0);
			}

			.be-icon {
				margin-right: rem-calc(12);
				font-size: rem-calc(24);
				height: 1em;
			}
		}

		&_link {
			@include fc(center, center);

			position: relative;
			flex-direction: column;
			flex: 1;
			font-weight: 500;
			box-shadow: inset rem-calc(0 -1 0) rgba($black, 0.25);
			overflow: hidden;

			&:not(:hover) {
				color: $black;
			}

			@include breakpoint(large) {
				&::after {
					content: '';
					display: block;
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: rem-calc(5);
					background-color: $primary-500;
					transform: translateY(100%);
					transition: transform 0.24s;
				}
			}

			.img {
				width: rem-calc(60);

				@include breakpoint(xlarge) {
					width: rem-calc(80);
				}
			}

			@include breakpoint-hover {
				&:hover {
					font-weight: 700;
					background-color: $gray-200;

					&::after {
						transform: translateY(0);
					}
				}
			}
		}
	}

	.swiper-slide {
		height: auto;
	}

	.media-embed {
		pointer-events: none;
		height: 100%;
		background-color: $black;
	}
}

// Ani
.sec-kv {
	&.js-ani {
		.kv_nav {
			@include set-ani(1s);
			transform: translateX(-50px);
		}

		.kv_cont {
			@include breakpoint(large) {
				@include set-ani(1s);
				transform: translateX(50px);
			}
		}

		+.sec-scroll {
			@include set-ani(1s, 0.5s);
			transform: translateY(30px);
		}

		&.is-animated {
			@include set-animated('.kv_nav, .kv_cont');

			+.sec-scroll {
				opacity: 1;
				transform: none;
			}
		}
	}
}

// scroll
.sec-scroll {
	.scroll-down {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		position: relative;
		padding-top: rem-calc(35);
		color: $gray-400;

		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: calc(50%);
			transform: translateX(-50%);
			width: rem-calc(1);
			height: rem-calc(24);
			background-color: $gray-400;
		}

		.svg {
			margin-bottom: rem-calc(10);
			width: 24px;
		}

		&_txt {
			font-weight: 500;
			color: $gray-400;
		}
	}
}

// --------------------------------------------------------
// Sec: pleasure
.sec-pleasure {
	margin-top: rem-calc(50);
	margin-bottom: rem-calc(50);

	@include breakpoint(xxlarge) {
		margin-top: rem-calc(100);
		margin-bottom: rem-calc(100);
	}

	// .headline-box {
	// 	margin-bottom: rem-calc(30);
	// }

	// Tab
	.sec-tab {
		margin-bottom: rem-calc(20);

		@include breakpoint($pad) {
			margin-bottom: rem-calc(35);
		}
	}

	.tabs {
		@include fc(flex-start, center);

		padding-left: rem-calc(20);
		padding-right: rem-calc(20);
		border: 0;
		white-space: nowrap;
		overflow-x: auto;

		// 隱藏scrollbar
		&::-webkit-scrollbar {
			display: none;
			/* Chrome, Safari and Opera */
		}

		scrollbar-width: none;
		/* Firefox */
		-ms-overflow-style: none;
		/* IE and Edge */

		@include breakpoint(large) {
			margin: rem-calc(0 14);
			justify-content: center;
		}

		&-panel {
			padding: 0;
			max-width: rem-calc(1920);
			margin-left: auto;
			margin-right: auto;

			&.is-active {
				@include breakpoint($pad) {
					@include fc(center, center);
				}
			}
		}

		&-title {
			.tabs-link {
				font-weight: 300;
				line-height: calc(22 / 16);
				padding: rem-calc(14);

				@include breakpoint(large) {
					padding: rem-calc(16 24);
					line-height: 1.5;
				}

				&[aria-selected='true'] {
					font-weight: 700;
				}
			}
		}
	}

	// Cont
	.sec-cont {
		background-color: $gray-100;

		@include breakpoint($pad) {
			padding: rem-calc(70 0 70);
		}

		@include breakpoint(xxlarge) {
			padding: rem-calc(52 0 50);
		}
	}

	// cover
	.pleasure_cover {
		position: relative;

		@include breakpoint($pad_d down) {
			padding: rem-calc(20);
			padding-bottom: 0;
		}

		@include breakpoint($pad) {
			width: percentage(865/1920);
			flex: 0 0 auto;
		}

		.img {
			position: relative;

			@include breakpoint($pad) {
				margin-left: percentage(60/865);
				width: percentage(800/865);
				min-width: rem-calc(600);
			}

			@include breakpoint(xlarge) {
				margin-left: percentage(100/865);
				min-width: rem-calc(750);
			}

			@include breakpoint(big) {
				margin-left: rem-calc(160);
			}
		}
	}

	.pleasure_cont {
		@include breakpoint($pad) {
			width: percentage(1055/1920);
		}
	}

	.pleasure-swiper {
		position: relative;

		@include breakpoint($pad_d down) {
			padding: rem-calc(20);
		}
	}

	// Swiper
	.swiper-container {
		@include breakpoint($pad_d down) {
			overflow: visible;
		}

		@include breakpoint($pad) {
			padding: rem-calc(20 40 20 0);
		}
	}

	.swiper-slide {
		width: rem-calc(200);

		@include breakpoint(xlarge) {
			width: rem-calc(250);
		}
	}

	.swiper-button-wrap {
		@include breakpoint($pad_d down) {
			margin: rem-calc(30) auto 0;
		}

		@include breakpoint($pad) {
			position: absolute;
			top: rem-calc(-36);
			right: rem-calc(40);
		}

		@include breakpoint(xxlarge) {
			right: rem-calc(60);
		}
	}
}

// Ani
.sec-pleasure {
	&.js-ani {
		$animateEl: '.headline-box',
		'.sec-tab';
		$animateElEnd: '.headline-box',
		'.sec-tab',
		'.pleasure_cover, .pleasure_cont';

		.pleasure_cover {
			@include set-ani(1s, 0.9s);
			transform: translateX(-50px);
		}

		.pleasure_cont {
			@include set-ani(1s, 0.9s);
			transform: translateX(50px);
		}

		@include set-animate($animateEl);

		&.is-animated {
			@include set-animated($animateElEnd);
		}
	}
}

// --------------------------------------------------------
// Sec: icare
.sec-icare {
	position: relative;

	&::before {
		pointer-events: none;
		content: '';
		display: block;
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		width: 100%;
		height: rem-calc(431);
		background: linear-gradient(179.97deg, #b6b5b2 82.14%, rgba(182, 181, 178, 0) 96.16%);

		@include breakpoint(medium) {
			height: percentage(431/690);
			background: linear-gradient(180deg, #b6b5b2 31.25%, rgba(182, 181, 178, 0) 100%);
		}
	}

	.grid-container {
		position: relative;
		z-index: 2;
	}

	.sec-cont {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		padding-top: rem-calc(50);

		@include breakpoint(xlarge) {
			padding-top: rem-calc(90);
		}
	}

	.dividing {
		margin: .9375rem auto;
		max-width: 74.75rem;
		width: calc(100% - 2.5rem);
		padding: 0 1.25rem;
		height: .8125rem;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;

		@include breakpoint(medium) {
			margin-top: 1.625rem;
			margin-bottom: 1.625rem;
		}
	}

	// Head
	.icare_cont {
		position: relative;
		z-index: 1;
		text-align: center;

		@include breakpoint(medium only) {
			max-width: rem-calc(480);
			margin-left: auto;
			margin-right: auto;
		}

		.btn {
			@include breakpoint(medium) {
				min-width: 14.25rem;
			}

			@include breakpoint(xlarge) {
				font-size: 1.625rem;
			}
		}
	}

	.sec-entitle {
		color: $gray-100;
	}

	.sec-title {
		color: $white;

		@include breakpoint(large) {
			margin-left: rem-calc(20);
		}
	}

	// bg
	.icare_bg {
		position: relative;
		z-index: 0;
		pointer-events: none;

		@include breakpoint(small down) {
			padding-top: rem-calc(320);
		}
	}

	.rwd-bg {
		&::before {
			padding-top: percentage(750/760);

			@include breakpoint(medium) {
				padding-top: percentage(955/1920);
				min-height: rem-calc(900);
			}
		}
	}

	// inner
	.icare_radio {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-wrap: wrap;

		@include breakpoint(large only) {
			width: rem-calc(700);
			margin-left: auto;
			margin-right: auto;
		}
	}

	.btn-box {
		@include breakpoint(medium down) {
			text-align: center;
		}
	}
}

// Ani
.sec-icare {
	&.js-ani {
		$animateEl: '.sec-cont, .icare_bg';

		.sec-cont {
			@include set-ani(1s);
			transform: translateY(30px);
			z-index: 1;

			@include breakpoint(large) {
				transform: translateX(-50px);
			}
		}

		.icare_bg {
			@include set-ani(1s);
			transform: translateY(30px);

			@include breakpoint(large) {
				transform: translateX(50px);
			}
		}

		&.is-animated {
			@include set-animated($animateEl);
		}
	}
}

// --------------------------------------------------------
// Sec: story
.sec-story {
	padding-top: rem-calc(50);

	@include breakpoint(xxlarge) {
		padding-top: rem-calc(100);
	}

	.grid-container {
		position: relative;
		z-index: 1;
	}

	.grid-container-p0 {
		overflow: hidden;
	}

	.sec-cont {
		position: relative;
		padding-bottom: rem-calc(22);

		@include breakpoint(large) {
			padding-bottom: rem-calc(40);
		}

		@include breakpoint(xxlarge) {
			padding-bottom: rem-calc(50);
			min-height: rem-calc(620);
		}

		&::before {
			pointer-events: none;
			content: '';
			display: block;
			position: absolute;
			z-index: 0;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			height: 100%;
			width: 110%;
			background-image: url(../images/index/story-bg-mb.png);
			background-repeat: no-repeat;
			background-position: bottom;
			background-size: 100%;

			@include breakpoint(medium) {
				width: rem-calc(1920);
				background-size: contain;
				background-image: url(../images/index/story-bg-pc.png);
			}

			@include breakpoint(big) {
				width: 100%;
			}
		}
	}

	// Ta
	.sec-tab {
		@include fc(center, center);

		position: relative;
		z-index: 1;
		margin-bottom: rem-calc(10);
		overflow-x: auto;
		overflow-y: hidden;

		// 隱藏scrollbar
		&::-webkit-scrollbar {
			display: none;
			/* Chrome, Safari and Opera */
		}

		scrollbar-width: none;
		/* Firefox */
		-ms-overflow-style: none;
		/* IE and Edge */

		@include breakpoint(large) {
			margin-bottom: rem-calc(40);
		}
	}

	.tabs {
		@include fc(flex-start, flex-start);

		white-space: nowrap;

		&-content {
			position: relative;
			z-index: 1;
		}

		&-panel {
			padding: 0;
		}

		&-title {
			position: relative;
			float: none;

			&:not(:last-child) {
				margin: 0;

				&::before {
					content: '';
					display: block;
					position: absolute;
					z-index: 0;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
					width: rem-calc(2);
					height: rem-calc(36);
					background-color: $gray-300;
				}
			}

			&.is-active {
				.tabs-link {
					color: $black;
					background-color: transparent;
				}
			}
		}

		&-link {
			@include bulidFZ(20, 36);

			padding: rem-calc(0 18);
			font-weight: 500;
			line-height: rem-calc(30);
			color: $gray-400;

			&:focus {
				color: $black;
				background-color: transparent;
			}

			@include breakpoint(large) {
				padding: rem-calc(0 50);
			}

			@include breakpoint(xlarge) {
				line-height: rem-calc(50);
			}
		}
	}

	.story_shadow {
		position: absolute;
		z-index: 0;
		filter: blur(40px);
		width: percentage(1358/1920);
		bottom: rem-calc(28);
		left: 50%;
		transform: translateX(-50%);
		background: $white;

		&::before {
			content: '';
			display: block;
			padding-top: percentage(592/1358);
		}
	}

	.more-link {
		margin-top: 0;
	}

	// Swiper
	.story-swiper {
		padding: rem-calc(20 0);

		@include breakpoint(large) {
			padding-top: rem-calc(23);
		}
	}

	.swiper-container {
		padding: rem-calc(20 0 24);
		overflow: visible;

		@include breakpoint(large) {
			padding: rem-calc(27 0 50);
		}
	}

	.swiper-slide {
		@include breakpoint($pad_d down) {
			max-width: percentage(250/375);
		}

		&.swiper-slide-active {
			z-index: 1;

			.video_info {
				transform: translateY(#{calc(-19px)});

				@include breakpoint(large) {
					transform: translateY(#{calc(-29px)});
				}
			}

			.video_cover {
				transform: scale(#{calc(569 / 467)});
			}
		}
	}

	.swiper-button-wrap {
		margin: auto;
	}
}

// Ani
.sec-story {
	&.js-ani {
		$animateEl: '.grid-container, .sec-tab',
		'.tabs-content, .more-ctrl';

		@include set-animate($animateEl);

		&.is-animated {
			@include set-animated($animateEl);
		}
	}
}

// --------------------------------------------------------
// Sec: lifestyle
.sec-lifestyle {
	padding: rem-calc(50 0);

	@include breakpoint(xxlarge) {
		padding: rem-calc(100 0 140);
	}

	.lifestyle_list {
		&_item {
			&:not(:last-child) {
				margin-bottom: rem-calc(10);

				@include breakpoint(large) {
					margin-bottom: rem-calc(30);
				}
			}
		}
	}
}

// Ani
.sec-lifestyle {
	&.js-ani {
		$animateEl: '.headline-box',
		'.sec-cont';

		@include set-animate($animateEl);

		&.is-animated {
			@include set-animated($animateEl);
		}
	}
}

// --------------------------------------------------------
// Sec: sakura
.sec-sakura {
	.sakura_title {
		margin-bottom: rem-calc(30);

		@include breakpoint(large) {
			margin-bottom: rem-calc(70);
		}

		.img {
			width: rem-calc(177);

			@include breakpoint(large) {
				width: rem-calc(287);
			}
		}
	}

	.sakura_gallery {
		@include fc(space-between, center);

		.img {
			width: calc(33.3333% - #{rem-calc(2)});

			@include breakpoint(large) {
				width: calc(33.3333% - #{rem-calc(10)});
			}
		}
	}
}

// Ani
.sec-sakura {
	&.js-ani {
		opacity: 0;
		transition: 0.5s opacity;
		will-change: opacity;

		&.is-animated {
			opacity: 1;
		}
	}
}

// --------------------------------------------------------