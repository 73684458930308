// 說明 (完整說明見) src/sass/style.sass
//	* 此檔案包含：通用於所有SCSS/SASS變數檔案
//      - 變數、mixin、function
//      - 所有單元都建議須要載入之樣式
//      - 避免寫實際的樣式於該檔案內（包含檔案內 @import 之其它CSS檔案）

@charset 'utf-8';

// --------------------------------------------------------
// Vendor

// Icon Font
@import '../vendor/font/icons'; // 載入Icon Font 變數

// Function
@import '../vendor/trig'; // 三角函數
@import '../vendor/photoshop-function'; // Photoshop 效果轉換

// --------------------------------------------------------
// Variable
// --------------------------------------------------------

$primary-color: #c9151e; // Foundation 主顏色變數

$primary-200: #faebeb;
$primary-300: #f6cdcd;
$primary-400: #e87f89;
$primary-500: #c9151e;
$primary-600: #e4030e;

$gray-100: #f9f9f9;
$gray-200: #f5f5f5;
$gray-300: #dddddd;
$gray-350: #d9d9d9;
$gray-400: #aeaeae;
$gray-500: #565656;
$gray-600: #434343;

$grad-black: linear-gradient(88.96deg, #595959 7.21%, #2e2f33 45.93%);
$grad-gray: linear-gradient(139.67deg, #595959 19.98%, #eaeaea 136.59%);
$grad-grayShine: linear-gradient(90deg, #4d4d4d 2%, #f6f6f6 48.96%, #9c9c9c 96%);
$grad-pink: linear-gradient(90deg, #dabcbb 0%, #f8e2e1 35.26%, #edc7c5 64.74%, #8a6c6b 100%);

$white: #fff;
$black: #000;

$color-hr: #dcdcdc;
$color-error: #e4030e;

$color-input: $black;
$color-input-border: $black;

// card
$card-border: #D9D9D9;

// Txt
$main-txt: $gray-600;
$main-link-color: $primary-color;
$bg-footer: $gray-100;

// font-family
$font-family-base: 'Noto Sans TC', 'sans-serif';

// MinScreenHeight
$minScreenHeight: 768;

$iphone: 375;
$iphone_d: 374;
$pad: 768;
$pad_d: 767;
$laptop: 1600;
$laptop_d: 1599;

// Grid Container Padding
$containerPaddingMb: 20;
$containerPaddingPad: 50;
$containerPaddingPc: 100;

$transition: #{0.24s};

// --------------------------------------------------------
// Placeholder
// --------------------------------------------------------
// @include bulidFZ(14, 16);
// @include bulidFZ(16, 20);
// @include bulidFZ(18, 26);

@mixin bulidFZ($min, $max) {
	$midFz: ($min + $max) / 2;

	font-size: rem-calc($min);

	@include breakpoint(medium) {
		font-size: rem-calc($midFz);
	}

	@include breakpoint(xlarge) {
		font-size: rem-calc($max);
	}
}

@mixin grid-padding {
	padding-right: rem-calc($containerPaddingMb);
	padding-left: rem-calc($containerPaddingMb);

	@include breakpoint(large) {
		padding-right: rem-calc($containerPaddingPad);
		padding-left: rem-calc($containerPaddingPad);
	}

	@include breakpoint(xxlarge) {
		padding-right: rem-calc($containerPaddingPc);
		padding-left: rem-calc($containerPaddingPc);
	}
}

// --------------------------------------------------------
// Function
// --------------------------------------------------------
// Mixin

// Flex
@mixin fc($justify, $align) {
	display: flex;

	@if $justify {
		justify-content: $justify;
	}

	@if $align {
		align-items: $align;
	}
}

// 遵循BEM的規則，於class增加tag（僅限於單層方式，多層不適用）
// 可使用（單層）： .component__link => a.component__link
// 不適用（多層）： .component .component__link => .component a.component__link
@mixin bem-tag($tag: 'a') {
	$current: &;

	@at-root #{$tag}#{$current} {
		@content;
	}
}

// 使用 ::before 的 padding-top 撐高度
@mixin before-img-size($width: 1, $height: 1, $build-other: true) {
	@if $build-other {
		content: '';
		display: block;
	}

	padding-top: percentage($height / $width);
}

// ---------------------------------------- [START] Media Query 相關
// Hover Media Query (使用 Foundation Mixin)
$project-has-ie: false; // 專案是否有含 IE => 有 IE 就不能使用 @media hover

@mixin breakpoint-hover($hover-enable: true) {
	// 有 IE 專案
	@if $project-has-ie {
		@if $hover-enable {
			@media screen and (min-width: 75rem) {
				@content;
			}
		} @else {
			@media screen and (max-width: 74.9375rem) {
				@content;
			}
		}
	}

	// 無 IE 專案
	@else {
		@if $hover-enable {
			@media (hover: hover) {
				@content;
			}
		} @else {
			@media (hover: none) {
				@content;
			}
		}
	}
}

// Breakpoint Between (使用 Foundation Mixin)
@mixin breakpoint-between($start, $end) {
	@media screen and (min-width: #{$start}px) and (max-width: #{$end}px) {
		@content;
	}
}

// ---------------------------------------- [END] Media Query 相關

// ---------------------------------------- [START] Custom Scroll Bar
$scrollbar-thumb-color: #dfdfdf; // 操作顏色
$scrollbar-track-color: #f0efef; // 滾軸背景
$scrollbar-width: 4px; // 寬度(瀏覽器預設為15px)
$scrollbar-border-radius: 4px; // 圓角，Firefox不支援

@mixin custom-scroll-bar($thumb-color: $scrollbar-thumb-color, $track-color: $scrollbar-track-color) {
	// For Chrome, Opera, Safari, Android, iOS
	&::-webkit-scrollbar {
		width: $scrollbar-width;
		height: $scrollbar-width;
	}

	&::-webkit-scrollbar-track {
		background: $track-color;
	}

	&::-webkit-scrollbar-thumb {
		background: $thumb-color;
		border-radius: $scrollbar-border-radius;

		&:hover {
			background: darken($thumb-color, 10%);
		}
	}

	&::-ms-scrollbar {
		width: $scrollbar-width;
	}

	@if & {
		// For Firefox (版本必須64+)
		// 如果是整頁<body>要使用，必須將Class增加於<html>標籤上
		// Ref: https://stackoverflow.com/a/6165489/11240898
		// 顏色：操作顏色(Thumb) 滾軸背景(Track)
		scrollbar-color: $thumb-color $track-color;
		// 寬度只有 auto | thin | none 選項
		// auto為15px，thin為6px，none為不顯示
		scrollbar-width: thin;
	}
}

// ---------------------------------------- [END] Custom Scroll Bar

// ---------------------------------------- [START] Animation Set
@mixin set-ani($duration: 1s, $delay: 0s) {
	opacity: 0;
	transition-duration: $duration;
	transition-property: opacity, transform;
	transition-delay: $delay;
	will-change: transform;
}

@mixin set-animate($animateEl) {
	@each $class in $animateEl {
		$i: index($animateEl, $class);
		$i: $i;

		#{$class} {
			opacity: 0;
			transform: translateY(30px);
			@include set-ani(1s, #{$i * 0.3s});
		}
	}
}

@mixin set-animated($animateEl) {
	@each $class in $animateEl {
		#{$class} {
			opacity: 1;
			transform: none;
		}
	}
}

@mixin set-animate-flow($animateEl) {
	&.js-ani {
		@include set-animate($animateEl);

		&.is-animated {
			@include set-animated($animateEl);
		}
	}
}
// ---------------------------------------- [END] Animation Set
