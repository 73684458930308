@import '../../foundation/foundation';
@import '../../layout/variable_mixin_function';

// --------------------------------------------------------

/* Base */
.swiper-box {
	position: relative;
	margin-right: auto;
	margin-left: auto;
}

.swiper-container {
	opacity: 0;
	transition: 0.36s opacity;

	&.swiper-container-initialized {
		opacity: 1;
	}
}

// --------------------------------------------------------
// 頁碼
.swiper-pagination {
	$root: &;

	position: relative !important;
	display: flex;

	&:active,
	&:focus {
		outline: 0;
	}

	&-bullet {
		position: relative;
		margin: rem-calc(0 5);
		width: rem-calc(8);
		height: rem-calc(8);
		background: transparent;
		opacity: 1;

		@include breakpoint(medium) {
			margin: rem-calc(0 11);
			width: rem-calc(10);
			height: rem-calc(10);
		}

		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			border: 0;
			background-color: $black;
			box-sizing: border-box;
			transition: border-color 0.3s, background-color 0.3s;
		}

		&-active {
			&::before {
				background-color: $white;
			}
		}

		&:only-child {
			visibility: hidden;
		}
	}

	&.sty-line {
		#{$root}-bullet {
			width: rem-calc(30);
			height: rem-calc(5);

			@include breakpoint(medium) {
				width: rem-calc(56);
				height: rem-calc(6);
			}

			&::before {
				border-radius: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	// pagination 手機版尺寸
	&.line-mb-2 {
		@include breakpoint(medium down) {
			.swiper-pagination-bullet {
				width: .75rem;
				margin: 0 .3125rem;
			}
		}
	}

	// pagination 紅色&白底
	&.sty-red {
		.swiper-pagination-bullet {
			&::before {
				background: $gray-300;
			}

			&.swiper-pagination-bullet-active {
				&::before {
					background: $primary-color;
				}
			}
		}
	}
}

// --------------------------------------------------------
// 按鈕
.swiper-button-wrap {
	position: relative;
	z-index: 1;
	width: rem-calc(90);
	height: rem-calc(40);

	@include breakpoint(large) {
		width: rem-calc(100);
	}
}

.swiper-button {

	&-prev,
	&-next {
		padding: 0;
		top: 50%;
		width: rem-calc(40);
		height: rem-calc(40);
		color: $primary-500;
		background-color: $white;
		font-size: rem-calc(24);
		border-radius: 50%;
		border: rem-calc(2) solid $primary-500;
		transition: 0.24s all;

		&.sty-white {
			color: $white;
			border-color: inherit;
			background: rgba($black, 0.2);
		}

		&.swiper-button-disabled {
			color: $gray-400;
			border-color: $gray-400;
			opacity: 1;
		}

		&::after {
			content: '';
		}

		.be-icon {
			&::before {
				margin: 0;
			}
		}
	}

	&-next {
		right: 0;
	}

	&-prev {
		left: 0;
	}
}

// --------------------------------------------------------
// 按鈕
.swiper-lazy {
	opacity: 0;
	transition: 0.3s opacity;

	&.swiper-lazy-loaded {
		opacity: 1;
	}
}