// @import '../../swiper-vars.scss';

:root {
	--swiper-navigation-size: 44px;
	/*
  --swiper-navigation-color: var(--swiper-theme-color);
  */
}
.swiper-button-prev,
.swiper-button-next {
	position: absolute;
	top: 50%;
	width: calc(var(--swiper-navigation-size) / 44 * 27);
	height: var(--swiper-navigation-size);
	margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
	z-index: 10;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--swiper-navigation-color, var(--swiper-theme-color));
	&.swiper-button-disabled {
		opacity: 0.35;
		cursor: auto;
		// pointer-events: none;
	}
	// &:after {
	//   font-family: swiper-icons;
	//   font-size: var(--swiper-navigation-size);
	//   text-transform: none !important;
	//   letter-spacing: 0;
	//   text-transform: none;
	//   font-variant: initial;
	//   line-height: 1;
	// }
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
	&:after {
		content: 'prev';
	}
	left: 10px;
	right: auto;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
	&:after {
		content: 'next';
	}
	right: 10px;
	left: auto;
}

@each $navColorName, $navColorValue in $colors {
	.swiper-button-prev,
	.swiper-button-next {
		&.swiper-button-#{'' + $navColorName} {
			--swiper-navigation-color: #{'' + $navColorValue};
		}
	}
}
.swiper-button-lock {
	display: none;
}
